(function(window, document, $, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {});

    /* Private */
    var container;
    var img;
    var canvas;
    var ctx;
    var croppedImg = document.getElementById('eedition-clip-cropped-img');

    var scroller;
    var mapCanvas;
    var _iscroll;

    var rect = {};
    var targetRect = {};
    var startMouseX = 0;
    var startMouseY = 0;
    var endMouseX = 0;
    var endMouseY = 0;
    var mouseX = 0;
    var mouseY = 0;
    var mouseDown = false;

    var pointerTarget = null;
    var cursorState = null;

    var acceptBtn = {};
    var closeBtn = {};
    var redrawBtn = {}
    var scaleHandle = {};

    var resizeCanvas = function(el) {
        canvas.width = el.offsetWidth;
        canvas.height = el.offsetHeight;
    }

    var resetCanvas = function() {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas
        ctx.fillStyle = 'rgba(0,0,0,0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    var rectExists = function() {
        if (
            typeof rect.x !== 'undefined' &&
            typeof rect.y !== 'undefined' &&
            typeof rect.w !== 'undefined' &&
            typeof rect.h !== 'undefined'
        ) {
            return true;
        }
        return false;
    };

    var drawClippingBox = function(x, y, w, h) {
        var scale = getScale(scroller);
        ctx.clearRect(x, y, w, h);
        ctx.beginPath();
        ctx.rect(x, y, w, h);
        ctx.strokeStyle = '#111';
        ctx.lineWidth = 2 / scale;
        ctx.stroke();
    };

    var drawShape = function (x, y, type, params) {
        ctx.beginPath();
        ctx.translate(x, y);

        var scale = getScale(scroller);
        if (scale !== 1) {
            if (type == 'line') {
                params.points.map(function(p) {
                    p.x /= scale;
                    p.y /= scale;
                });
            } else if (type == 'arc') {
                params.centerX /= scale;
                params.centerY /= scale;
                params.radius /= scale;
            }
        }

        if (type == 'arc') {
            ctx.arc(params.centerX, params.centerY, params.radius, params.startAngle, params.endAngle);
        } else if (type == 'line') {
            ctx.moveTo(params.points[0].x, params.points[0].y);
            for (var i=1; i<params.points.length; i++) {
                ctx.lineTo(params.points[i].x, params.points[i].y);
            }
        } else if (type == 'rect') {
            ctx.rect(0, 0, params.w, params.h);
        }

        if (params.stroke) {
            ctx.strokeStyle = params.strokeStyle || '#FFF';
            ctx.lineWidth = params.lineWidth || 4 / scale;
            ctx.stroke();
        } else if (params.fill) {
            ctx.fillStyle = params.fillStyle || '#111';
            ctx.fill();
        }

        ctx.translate(-x, -y);
    };

    var pointInRect = function(x, y, rect) {
        if (
            x > rect.x && x < rect.x + rect.w &&
            y > rect.y && y < rect.y + rect.h
        ) {
            return true;
        }
        return false;
    };

    var getScale = function(el) {
        var matrix = window.getComputedStyle(el).transform;
        var matrixArray = matrix.replace('matrix(', '').split(',');
        return parseFloat(matrixArray[0]); // convert from string to number
    };

    var clamp = function(num, min, max) {
        return Math.min(Math.max(num, min), max);
    }

    var canvasEnabled = false;

    /* Public */
    me.clip = {

        init: function() {
            if (canvasEnabled) {
                me.clip.deactivateCanvas();
            } else {
                me.clip.activateCanvas();
            }
        },

        activateCanvas: function() {
            canvasEnabled = true;

            if (document.getElementById('eedition-page-container').classList.contains('mode-map')) {
                container = document.getElementById('eedition-map-container');
                scroller = container.querySelector('.eedition-map-scroller');
                _iscroll = me.iscroll.map;
                img = scroller.querySelector('#eedition-image');
                mapCanvas = scroller.querySelector('#eedition-canvas-flags');
            } else {
                container = document.getElementById('eedition-segments-container');
                scroller = container.querySelector('.eedition-scroller');
                _iscroll = me.iscroll.segments;
                img = scroller.querySelector('.image-plain');
            }

            container.classList.remove('eedition-grabbable');

            // Disable scrolling, zooming, swiping
            _iscroll.disable();

            var zoomBtns = document.querySelectorAll('.eedition-zoom');
            for (var i=0; i<zoomBtns.length; i++) {
                zoomBtns[i].dataset.disabled = true;
            }

            if (mapCanvas) {
                mapCanvas.style.display = 'none';
            }

            canvas = document.createElement('canvas');
            canvas.id = 'eedition-clip-canvas';
            canvas.classList.add('canvas');
            ctx = canvas.getContext('2d');
            scroller.appendChild(canvas);

            img.crossOrigin = 'anonymous';

            resizeCanvas(img);
            resetCanvas();

            // Event listeners
            document.addEventListener('pointerup', me.clip.handleMouseup, false);
            document.addEventListener('pointerdown', me.clip.handleMousedown, false);
            document.addEventListener('pointermove', me.clip.handleMousemove, false);

            if (localStorage.getItem('eedition-clipping-tutorial-dont-show') !== '1') {
                $('#eedition-clip-tutorial-modal').modal('show');
            }
        },

        deactivateCanvas: function() {
            // set a small timeout so user doesn't end up clicking segment underneath cancel button
            setTimeout(function(){
                canvasEnabled = false;

                // Enable scrolling, zooming, swiping
                _iscroll.enable();
                container.classList.add('eedition-grabbable');

                if (mapCanvas) {
                    mapCanvas.style.display = 'block';
                    mapCanvas = undefined;
                }

                var zoomBtns = document.querySelectorAll('.eedition-zoom');
                for (var i=0; i<zoomBtns.length; i++) {
                    zoomBtns[i].dataset.disabled = false;
                }

                rect = {};
                acceptBtn = {};
                closeBtn = {};
                redrawBtn = {}
                scaleHandle = {};

                document.removeEventListener('pointerup', me.clip.handleMouseup, false);
                document.removeEventListener('pointerdown', me.clip.handleMousedown, false);
                document.removeEventListener('pointermove', me.clip.handleMousemove, false);

                canvas.parentNode.removeChild(canvas);
            }, 100);
        },

        handleMousedown: function(e) {
            if (e.button !== 0) return;
            if (e.target !== canvas) return;

            // Remove any selections 
            (window.getSelection ? window.getSelection() : document.selection).removeAllRanges();

            mouseDown = true;

            targetRect = e.target.getBoundingClientRect();
            startMouseX = parseInt(e.clientX - targetRect.left);
            startMouseY = parseInt(e.clientY - targetRect.top);

            var scale = getScale(scroller);

            startMouseX /= scale;
            startMouseY /= scale;

            // Pointer is down on accept button
            if (pointInRect(startMouseX, startMouseY, acceptBtn)) {
                pointerTarget = 'acceptBtn';

            // Pointer is down on redraw button
            } else if (pointInRect(startMouseX, startMouseY, redrawBtn)) {
                pointerTarget = 'redrawBtn';

            // Pointer is down on close button
            } else if (pointInRect(startMouseX, startMouseY, closeBtn)) {
                pointerTarget = 'closeBtn';

            // Pointer is down on scale handle
            } else if (pointInRect(startMouseX, startMouseY, scaleHandle)) {
                pointerTarget = 'scaleHandle';
                cursorState = 'scaling';
                canvas.style.cursor = 'nwse-resize';

            // Pointer is down on rect
            } else if (pointInRect(startMouseX, startMouseY, rect)) {
                pointerTarget = 'rect';
                cursorState = 'grabbing';
                canvas.style.cursor = 'grabbing';

            // Pointer is down on canvas
            } else {
                pointerTarget = 'canvas';
            }
        },

        handleMousemove: function(e) {
            mouseX = parseInt(e.clientX - targetRect.left);
            mouseY = parseInt(e.clientY - targetRect.top);

            var scale = getScale(scroller);
            mouseX /= scale;
            mouseY /= scale;

            if (mouseDown) {
                if (pointerTarget == 'canvas' || pointerTarget == 'rect' || pointerTarget == 'scaleHandle') {
                    resetCanvas();

                    // Drawing rect
                    if (pointerTarget == 'canvas') {
                        var width = mouseX - startMouseX;
                        var height = mouseY - startMouseY;

                        drawClippingBox(startMouseX, startMouseY, width, height);

                    // Moving rect
                    } else if (pointerTarget == 'rect') {
                        var dx = mouseX - startMouseX;
                        var dy = mouseY - startMouseY;

                        var nx = clamp(rect.x + dx, 0, canvas.width - rect.w);
                        var ny = clamp(rect.y + dy, 0, canvas.height - rect.h);

                        drawClippingBox(nx, ny, rect.w, rect.h);

                    // Scaling rect
                    } else if (pointerTarget == 'scaleHandle') {
                        var dx = mouseX - startMouseX;
                        var dy = mouseY - startMouseY;

                        drawClippingBox(rect.x, rect.y, rect.w + dx, rect.h + dy);
                    }
                }
            }

            if (cursorState != 'grabbing' && cursorState != 'scaling') {
                if (pointInRect(mouseX, mouseY, scaleHandle)) {
                    canvas.style.cursor = 'nwse-resize';
                } else if (
                    pointInRect(mouseX, mouseY, acceptBtn) ||
                    pointInRect(mouseX, mouseY, redrawBtn) ||
                    pointInRect(mouseX, mouseY, closeBtn)
                ) {
                    canvas.style.cursor = 'pointer';
                } else if (pointInRect(mouseX, mouseY, rect)) {
                    canvas.style.cursor = 'grab';
                } else {
                    canvas.style.cursor = 'default';
                }
            }
        },

        handleMouseup: function(e) {
            if (e.button !== 0) return;

            if (mouseDown) {
                endMouseX = parseInt(e.clientX - targetRect.left);
                endMouseY = parseInt(e.clientY - targetRect.top);

                var scale = getScale(scroller);
                endMouseX /= scale;
                endMouseY /= scale;

                // If endMouseX or endMouseY were off canvas, set them back on canvas
                endMouseX = clamp(endMouseX, 0, canvas.width);
                endMouseY = clamp(endMouseY, 0, canvas.height);

                mouseDown = false;

                if (cursorState == 'grabbing') {
                    cursorState = null;
                    canvas.style.cursor = 'grab';
                } else if (cursorState == 'scaling') {
                    cursorState = null;
                    canvas.style.cursor = 'nwsw-resize';
                }

                // Accept button clicked
                if (pointerTarget == 'acceptBtn' && pointInRect(endMouseX, endMouseY, acceptBtn)) {
                    me.clip.imageToCroppedImage(rect);
                    $('#eedition-clip-modal').modal('show');
                    document.getElementById('eedition-clip-cropped-img-save').href = croppedImg.src;

                // Redraw button clicked
                } else if (pointerTarget == 'redrawBtn' && pointInRect(endMouseX, endMouseY, redrawBtn)) {
                    rect = {};
                    resetCanvas();

                // Close button clicked
                } else if (pointerTarget == 'closeBtn' && pointInRect(endMouseX, endMouseY, closeBtn)) {
                    rect = {};
                    resetCanvas();
                    me.clip.init();

                // Cases where we have to redraw
                } else if (pointerTarget == 'scaleHandle' || pointerTarget == 'rect' || pointerTarget == 'canvas') {

                    // Scale rect
                    if (pointerTarget == 'scaleHandle') {
                        rect.w += endMouseX - startMouseX;
                        rect.h += endMouseY - startMouseY;

                    // Move rect
                    } else if (pointerTarget == 'rect') {
                        var dx = endMouseX - startMouseX;
                        var dy = endMouseY - startMouseY;

                        rect.x = clamp(rect.x + dx, 0, canvas.width - rect.w);
                        rect.y = clamp(rect.y + dy, 0, canvas.height - rect.h);

                    // Create rect
                    } else if (pointerTarget == 'canvas') {
                        rect = {
                            x: startMouseX,
                            y: startMouseY,
                            w: endMouseX - startMouseX,
                            h: endMouseY - startMouseY
                        };
                    }

                    // If rect width is negative, fix rect x and width
                    if (rect.w && rect.w < 0) {
                        rect.w = Math.abs(rect.w);
                        rect.x = rect.x - rect.w;
                    }

                    // If rect height is negative, fix rect y and height
                    if (rect.h && rect.h < 0) {
                        rect.h = Math.abs(rect.h);
                        rect.y = rect.y - rect.h;
                    }

                    // If the rect is outside of the canvas, resize it so it fits
                    if (rect.x + rect.w > canvas.width) {
                        rect.w = canvas.width - rect.x;
                    }
                    if (rect.y + rect.h > canvas.height) {
                        rect.h = canvas.height - rect.y;
                    }

                    // Reset and redraw clipping box
                    resetCanvas();
                    if (rectExists()) {
                        drawClippingBox(rect.x, rect.y, rect.w, rect.h);
                    }

                    // Draw controls
                    if (rectExists()) {
                        acceptBtn = {w:40, h:40};
                        redrawBtn = {w:40, h:40};
                        closeBtn = {w:40, h:40};
                        scaleHandle = {w:20, h:20};

                        if (scale !== 1) {
                            acceptBtn.w /= scale;
                            acceptBtn.h /= scale;
                            closeBtn.w /= scale;
                            closeBtn.h /= scale;
                            redrawBtn.w /= scale;
                            redrawBtn.h /= scale;
                            scaleHandle.w /= scale;
                            scaleHandle.h /= scale;
                        }

                        // Align controls
                        var controlsGap = 4 / scale;
                        var controlsWidth = acceptBtn.w + redrawBtn.w + closeBtn.w + controlsGap * 2; // 2 gaps

                        // Align right
                        if (rect.x + rect.w - controlsWidth > 0) {
                            acceptBtn.x = rect.x + rect.w - controlsWidth;
                        // Align left
                        } else if (rect.x + controlsWidth < canvas.width) {
                            acceptBtn.x = rect.x;
                        // Canvas align
                        } else {
                            acceptBtn.x = 0;
                        }
                        redrawBtn.x = acceptBtn.x + acceptBtn.w + controlsGap;
                        closeBtn.x = acceptBtn.x + acceptBtn.w + redrawBtn.w + controlsGap * 2; // 2 gaps

                        // Set controls y
                        if (rect.y + rect.h + acceptBtn.h + controlsGap <= canvas.height) {
                            acceptBtn.y = rect.y + rect.h + controlsGap;
                        } else if (rect.y >= acceptBtn.h + controlsGap) {
                            acceptBtn.y = rect.y - acceptBtn.h - controlsGap;
                        } else {
                            acceptBtn.y = rect.y + controlsGap;
                        }
                        redrawBtn.y = acceptBtn.y;
                        closeBtn.y = acceptBtn.y;

                        // Set scale handle x and y
                        scaleHandle.x = rect.x + rect.w - scaleHandle.w;
                        scaleHandle.y = rect.y + rect.h - scaleHandle.h;

                        // Draw buttons
                        drawShape(acceptBtn.x, acceptBtn.y, 'rect', {fill:true, w:acceptBtn.w, h:acceptBtn.h});
                        drawShape(acceptBtn.x, acceptBtn.y, 'line', {stroke:true, points:[{x:5.5,y:22.5},{x:13.5,y:30.5},{x:34.5,y:9.5}]});

                        drawShape(closeBtn.x, closeBtn.y, 'rect', {fill:true, w:closeBtn.w, h:closeBtn.h});
                        drawShape(closeBtn.x, closeBtn.y, 'line', {stroke:true, points:[{x:5.5,y:5.5},{x:34.5,y:34.5}]});
                        drawShape(closeBtn.x, closeBtn.y, 'line', {stroke:true, points:[{x:34.5,y:5.5},{x:5.5,y:34.5}]});

                        drawShape(redrawBtn.x, redrawBtn.y, 'rect', {fill:true, w:redrawBtn.w, h:redrawBtn.h});
                        drawShape(redrawBtn.x, redrawBtn.y, 'arc', {stroke:true, radius:11, centerX:20, centerY:20, startAngle:-22*(Math.PI/180), endAngle:279*(Math.PI/180)});
                        drawShape(redrawBtn.x, redrawBtn.y, 'line', {stroke:true, points:[{x:19,y:4},{x:24,y:9},{x:19,y:14}]});

                        // Draw scale handle (triangle)
                        if (rect.w > scaleHandle.w && rect.h > scaleHandle.h) {
                            drawShape(scaleHandle.x, scaleHandle.y, 'line', {fill:true, points:[{x:16,y:0},{x:16,y:16},{x:0,y:16}]});
                        }
                    }
                }
            }
        },

        imageToCroppedImage: function(rect) {
            var aspectRatioX = img.naturalWidth / canvas.width;
            var aspectRatioY = img.naturalHeight / canvas.height;

            var cv2 = document.createElement('canvas');
            cv2.width = rect.w * aspectRatioX;
            cv2.height = rect.h * aspectRatioY;
            var ctx2 = cv2.getContext('2d');
            ctx2.drawImage(img, rect.x*aspectRatioX, rect.y*aspectRatioY, rect.w*aspectRatioX, rect.h*aspectRatioY, 0, 0, cv2.width, cv2.height);
            var dataURI = cv2.toDataURL('image/jpeg');
            croppedImg.src = dataURI;
        },

        printCanvas: function () {
            if (document.getElementById('eedition-wrapper').dataset.nowapp === 'true') {
                var clipPrintEvent = new Event('nowapp_eedition_clip_print');
                document.dispatchEvent(clipPrintEvent);
                return;
            }
            var html = '<!DOCTYPE html>';
            html += '<html>'
            html += '<head><title>Print E-Edition Clipping</title></head>';
            html += '<body>'
            html += '<img src="' + croppedImg.src + '">';
            html += '</' + 'body>';
            html += '</html>';

            var printWin = window.open('');
            printWin.document.open();
            printWin.document.write(html);
            printWin.document.close();
            printWin.focus();
            printWin.print();
            printWin.onfocus = function () {
                setTimeout(function () {
                    printWin.close();
                }, 100);
            };
        },

        dontShow: function(e) {
            if (e.target.checked) {
                localStorage.setItem('eedition-clipping-tutorial-dont-show', '1');
            }
        }
    };

    document.getElementById('eedition-print-clip-btn').addEventListener('click', me.clip.printCanvas, false);
    document.getElementById('eedition-clip-tutorial-dont-show').addEventListener('click', me.clip.dontShow, false);
    if (document.getElementById('eedition-wrapper').dataset.nowapp === 'true') {
        document.getElementById('eedition-clip-cropped-img-save').addEventListener('click', function(e) {
            e.preventDefault();
            var clipSaveEvent = new Event('nowapp_eedition_clip_save');
            document.dispatchEvent(clipSaveEvent);
        }, false);
    }

})(window, document, $, __tnt);